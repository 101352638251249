#sidebar {
    position: fixed;
    overflow: auto;
    width: 100%;
    height: 100vh;
    background-color: $mainColor;
    &::-webkit-scrollbar {
        width: 5px;
        background: #b9b6b6;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }
    .main-logo {
        padding: 26px;
        filter: grayscale(1) invert(1);
    }
    ul.sidebar-menu li {
        [id*='sidebar_item'] a {
            padding-left: 4pc;
        }
        a {
            padding: 9px 7px 9px 29px;
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            position: relative;
            background: transparent;
            text-transform: uppercase;
            position: relative;
            &+.collapse,
            &+.collapsing {
                background-color: #ffffff3b;
            }
            &.active {
                background-color: #ffffff30;
                color: #fff;
                &:before {
                    content: '';
                    transition: all .5s;
                }
            }
            &:hover {
                background-color: #ffffff30;
                color: #fff !important;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 5px;
                background: #2196F3;
                transition: all .5s;
            }
            &:hover {
                &:before {
                    content: '';
                    transition: all .5s;
                }
            }
            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                i {
                    font-size: 11px;
                }
            }
            &>i {
                font-size: 17px;
            }
        }
    }
}

.sidebar-left {
    contain: content
}

.dashboard-right {
    height: 100vh;
    overflow: hidden scroll;
    width: 100%;
    flex: 1;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .collaspe_icon i,
    .notification-bell i {
        font-size: 23px;
        cursor: pointer;
    }
    .user-profile {
        img {
            padding: 2px;
            background-color: #fff;
            box-shadow: 4px 4px 15px #ccc;
            height: 45px;
            width: 45px;
        }
        &:hover {
            .user-profile-box {
                visibility: visible;
                opacity: 1;
                transition: all .5s;
            }
        }
        &-box {
            width: 200px;
            &:before {
                content: '';
                position: absolute;
                border-width: 20px 20px 0 0;
                border-style: solid;
                top: -.65pc;
                right: 1.2pc;
                transform: rotate(45deg);
            }
            ul {
                border-top: 1px solid #ffffff80;
                li {
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 7px;
                        margin-bottom: 15px;
                        &:hover {
                            color: #fff;
                        }
                    }
                    .ion-share {
                        transform: scaleX(-1);
                    }
                    &:last-child {
                        a {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            p,
            li,
            i,
            a {
                color: #fff;
                text-align: right;
            }
            p:first-child {
                font-size: 12px;
                color: #ffffff80;
            }
            i {
                font-size: 20px;
            }
            p,
            li {
                font-size: 14px;
            }
        }
    }
    .notification {
        &-bell {
            i {
                &+span.MuiBadge-badge {
                    background: black;
                    color: #fff;
                    animation: box-shadow 3.5s ease-out infinite;
                }
            }
            &:hover {
                .notification-box {
                    visibility: visible;
                    opacity: 1;
                    transition: all .5s;
                }
            }
        }
        &-box {
            &-content {
                height: 300px;
                display: flex;
                flex-direction: column;
                overflow: hidden scroll;
            }
            &-header {
                border-radius: 10px 10px 0 0;
            }
        }
    }
    .notification-box,
    .user-profile-box {
        border-radius: 10px;
        position: absolute;
        right: -2pc;
        z-index: 999;
        box-shadow: 0 0 10px #00000040;
        visibility: hidden;
        opacity: 0;
        transition: all .5s;
    }
}

.dashboard {
    &-filter {
        display: flex;
        gap: 20px;
        li .filtering {
            color: #415094;
            padding: 5px 20px;
            background: #fff;
            border: 1px solid #000;
            border-radius: 30px;
            font-size: 12px;
            text-align: center;
            line-height: 20px;
            font-weight: 500;
            &.active {
                color: #fff;
                background: #000;
            }
            &:hover {
                color: #fff !important;
                background: #000;
                transition: all .5s;
            }
        }
    }
    &-pills {
        display: flex;
        gap: 20px;
        li .nav-link {
            color: #415094;
            padding: 5px 20px;
            background: #fff;
            border: 1px solid #000;
            border-radius: 30px;
            font-size: 12px;
            text-align: center;
            line-height: 20px;
            font-weight: 500;
            &.active {
                color: #fff;
                background: #000;
            }
            &:hover {
                color: #fff !important;
                background: #000;
                transition: all .5s;
            }
        }
    }
    &-summary {
        margin-bottom: 1pc;
        box-shadow: 0px 10px 15px #6b6b6b29;
        border: 1px solid transparent;
        h5 {
            margin-bottom: 1pc;
        }
        &:hover {
            // background: url('../images/box-hover-bg.png') no-repeat center/cover;
            box-shadow: 0px 10px 30px #716f7503;
            border-color: #ccc;
            background-position: right center;
            transition: all 0.5s;
            // h5,
            // h4 {
            //     color: #fff !important;
            //     transition: all .5s;
            // }
        }
    }
}

.dragable-menu li {
    background-color: #f7f7f7;
    padding: 5px 1pc;
    margin-bottom: 1pc;
    cursor: move;
    border: 1px solid lightgray;
    font-size: 14px;
}

.customer-profile {
    &.bg-light {
        height: 225px;
    }
    img {
        height: 165px;
        width: 165px;
    }
    li {
        display: flex;
        gap: 18px;
        &>span {
            width: 175px;
            display: flex;
            justify-content: space-between;
        }
    }
}

.modal-body {
    .customer-profile {
        li {
            border-bottom: 1px solid #afafaf;
            padding-bottom: 1pc;
            margin-bottom: 1pc;
            &:last-child {
                border: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
    .show-product {
        .product-main-image {
            text-align: center;
            img {
                height: 250px;
                width: 300px;
                object-fit: contain;
            }
            &+h5+ul {
                img {
                    height: 100px;
                    width: auto;
                    object-fit: contain;
                    border: 1px solid #cbc7c7;
                }
            }
        }
        .customer-profile li {
            padding-bottom: 8px;
            margin-bottom: 8px;
        }
    }
}



.custom-modal-parent { z-index: 99999999999999999999999; position: relative;}.custom-modal { height: 80vh;
    overflow: auto;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white;padding: 25px; border-radius: 10px; z-index: 9999;}.custom-modal:focus-visible { outline: 0;}.custom-modal-overlay { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: #00000080; backdrop-filter: blur(1px);}.custom-modal-body { overflow: hidden;}

.btn-absolute {
    position: absolute;
    top: -8px;
    right: -20px;
    z-index: 10;
    padding: 3px 7px !important;
    border-radius: 50%;
}
.calender-date {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    color: #333;
    cursor: pointer;
    text-align: center;
}

.calender-date .day-of-week {
    color: #686868;
    font-size: 13px;
    line-height: 1;
    padding-top: 4px;
  }
  .calender-date p {
    margin-bottom: 0;
  }

  .calender-date .day-of-day {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    padding-top: 3px;
  
  }
  .calender-date .day-of-month {
    color: #686868;
    font-size: 13px;
    line-height: 1;
    padding-top: 3px;
    text-transform: uppercase;
  }

  .acordding .accordion-button {
    padding: 9px;
    box-shadow: none !important;
    background-color: #fff !important;
    border-radius: 0;
    color:#000;

  }
.accordion-button:not(.collapsed) {
    background-color:'white !important';
  }
.acordding .accordion {
    padding-bottom: 3px;
}
